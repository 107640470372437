import React from 'react'
import currencyService from '@services/CurrencyService'
import { useSite } from '@foundation/hooks/useSite'

type ProductPriceProps = {
  price: number | null
  // pass only if known e.g from a product object
  currency?: string
}

export type ProductPriceReturn = React.ReactNode

function Price(props: ProductPriceProps): JSX.Element {
  const { mySite } = useSite()
  const { locale } = mySite
  const { price, currency } = props

  if (price === null) {
    return <></>
  }

  const formattedPrice = currencyService.formatValueWithCurrency({
    value: +price,
    locale,
    currency,
  })

  return <span>{formattedPrice}</span>
}

export default Price
