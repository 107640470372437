import React from 'react'
import { StyledPriceWrapper, StyledRrpPrice } from './ProductTilePrice.style'
import { ProductPriceReturn } from '../ProductPrice/Price'

type ProductTilePriceProps<T extends ProductPriceReturn> = {
  rrp?: React.ReactNode
  offerPrice?: React.ReactNode
}

function ProductTilePrice<T extends ProductPriceReturn>({ rrp, offerPrice }: ProductTilePriceProps<T>): JSX.Element {
  const isOnOffer = !!(offerPrice && offerPrice)

  return (
    <StyledPriceWrapper variant="h5">
      {rrp && <StyledRrpPrice isOnOffer={isOnOffer}>{rrp}</StyledRrpPrice>}
      {isOnOffer && <span>{offerPrice}</span>}
    </StyledPriceWrapper>
  )
}

export default ProductTilePrice
