import styled from '@mui/material/styles/styled'

const CmsTeaserDetailText = styled('div', {
  name: 'CmsTeaser',
  slot: 'DetailText',
})(({ theme }) => ({
  ...theme.typography.body1,
  p: {
    margin: 0,
    ['&:not(:last-child)']: {
      marginBottom: theme.spacing(8),
    },
  },
  'a:-webkit-any-link': {
    color: theme.palette.text.primary,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: {
    '.align--left,& .align--left': {
      textAlign: 'left',
    },
    '.align--center,& .align--center': {
      textAlign: 'center',
    },
    '.align--right,& .align--right': {
      textAlign: 'right',
    },
    '.align--justify': {
      textAlign: 'justify',
    },
    '& .left': {
      textAlign: 'left',
    },
    '& .center': {
      textAlign: 'center',
    },
    '& .right': {
      textAlign: 'right',
    },
    '& .justify': {
      textAlign: 'justify',
    },
  },
  h1: {
    fontSize: 60,
  },
  h2: {
    fontSize: 40,
  },
  h3: {
    fontSize: 32,
  },
  '.text-small': {
    fontSize: 12,
  },
  '.text-large': {
    fontSize: 20,
    lineHeight: 1.7,
    [theme.breakpoints.up('sm')]: {
      fontSize: 40,
      lineHeight: 1.4,
    },
  },

  div: {
    overflowX: 'auto', // hack to have a horizontal scrollbar for tables
  },

  table: {
    ...theme.typography.body1,
    justifyContent: 'left',
    borderCollapse: 'collapse',
    minWidth: 960,
    width: '100%',
    tableLayout: 'fixed',

    'tr, td': {
      border: `1px solid ${theme.palette.neutral.tones[60]}`,
      textAlign: 'left',
      padding: theme.spacing(4, 8),
    },
    'tr:first-of-type': {
      ...theme.typography.subtitle2,
      backgroundColor: theme.palette.background[2],
    },
  },
  'table.center': {
    justifyContent: 'center',
  },
  'table.left': {
    justifyContent: 'left',
  },
  'table.right': {
    justifyContent: 'right',
  },

  'ul, ol': {
    paddingLeft: theme.spacing(10),
  },
}))

export default CmsTeaserDetailText
