import config from '@configs/index'
import { setLastSelectedProduct } from '@features/ui/action'
import { useAppDispatch } from '@hooks/redux'
import { TProduct } from '@utils/productNew'
import React, { useCallback, useMemo, useState } from 'react'
import { Link } from '@components/common/Link/Link'
import { ProductTileFooter } from './components/ProductTileFooter'
import { ProductTileImages } from './components/ProductTileImages'
import { getCluster, getMocoLinkTo, getProductInfo, getSeo } from './helpers'
import { useBadges, useTileDataElementId } from './hooks'
import { StyledSuggestedProductTile } from './SuggestedProductTile.style'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

const siteName = config.name

type TSuggestedProductTile = {
  product: TProduct
  tileIndex: number
  isImgWithShadow?: boolean
  lazy?: boolean
  variant?: string
  backgroundColor?: string
  showHeader?: boolean
}

export const SuggestedProductTile: React.FC<TSuggestedProductTile> = ({
  product,
  tileIndex,
  isImgWithShadow,
  variant,
  backgroundColor,
  lazy,
}) => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)
  const dispatch = useAppDispatch()
  const [selectedClusterIndex, setSelectedClusterIndex] = useState(0)

  // Product info
  const { productType, name, modelCode, productItems } = getProductInfo(product)

  // Clusters
  const clusters = getCluster(product)
  const clusterLength = clusters?.length ?? 0

  const selectedViewCluster = useMemo(
    () => (clusters.length ? clusters[selectedClusterIndex] : product),
    [clusters, selectedClusterIndex, product]
  )

  // Link
  const seo = getSeo(selectedViewCluster, productItems, product)
  const mocoLink = getMocoLinkTo(selectedViewCluster, productType, seo)

  // Data attrs
  const tileDataElementId = useTileDataElementId(tileIndex)

  // Badges
  const { priorityBadgeText } = useBadges(product, selectedViewCluster, clusterLength)

  // Callbacks
  const onProductChange = useCallback((index: number) => {
    setSelectedClusterIndex(index)
  }, [])

  const onImageClick = useCallback(() => {
    dispatch(setLastSelectedProduct(product?.productId || ''))
  }, [dispatch, product?.productId])

  if (!product) return null

  return (
    <Link
      href={mocoLink}
      aria-label={priorityBadgeText || `${name}_${modelCode}`}
      data-element-id={tileDataElementId}
      data-description={`${siteName}_${name}_${modelCode}`}
      locale={locale as string}
      isLocaleDomain={isLocaleDomain}
      locationOrigin={locationOrigin}
    >
      <StyledSuggestedProductTile width="400px">
        <ProductTileImages
          clusters={clusters}
          product={product}
          selectedViewCluster={selectedViewCluster}
          tileDataElementId={tileDataElementId}
          priorityBadgeText={priorityBadgeText}
          selectedClusterIndex={selectedClusterIndex}
          isImgWithShadow={isImgWithShadow}
          onImageClick={onImageClick}
          onProductChange={onProductChange}
          mocoLink={mocoLink}
          variant={variant}
          backgroundColor={backgroundColor}
          lazy={lazy}
        />
        <ProductTileFooter
          priorityBadgeText={priorityBadgeText}
          mocoLink={mocoLink}
          tileDataElementId={tileDataElementId}
          product={product}
          clusters={clusters}
        />
      </StyledSuggestedProductTile>
    </Link>
  )
}
