import KlarnaOnSiteMessage from '@components/Klarna/KlarnaOnSiteMessage'
import { PRODUCT_TYPES_KEYS } from '@constants/product'
import { useTranslation } from 'next-i18next'
import React from 'react'
import {
  KlarnaMessageContainerPDP,
  ProductCurrentPriceContainer,
  ProductPriceContainer,
  ProductPriceContainerPDP,
  ProductPriceCurrentPrice,
  ProductPriceDetails,
  ProductPriceInitialPrice,
  ProductPriceInitialPricePDP,
  ProductPriceLabel,
  ProductPricePricePerBox,
  ProductPricePricePerBoxContainer,
  ProductPricePricePerBoxCurrentPrice,
  ProductPricePricePerBoxInitialPrice,
  ProductPricePricePerBoxPrices,
  ProductPriceWrapper,
} from './ProductPrice.style'
import ProductTilePrice from './ProductTilePrice'
import { TPrice } from '@components/common/UI/ProductPrice/index.types'
import { getFinalPrice, getPriceLabel } from '@components/common/UI/ProductPrice/utils/utils'
import Price from './ProductPrice/Price'

export interface ProductPriceNew {
  price: TPrice | null
  isPDP?: boolean
  totalBoxes?: number
  isCL?: boolean
  productQuantity?: string[]
  isCLAccessories?: boolean
  isCompact?: boolean
  isRoxable?: boolean
  productType: string
  isForSuggestedProduct?: boolean
  showPricePerBox?: boolean
  showTotalPrice?: boolean
  showSalePrice?: boolean
}

const ProductPriceNew: React.FC<ProductPriceNew> = ({
  price,
  isPDP = false,
  totalBoxes = 2,
  isCL = false,
  productQuantity,
  isCLAccessories = false,
  isCompact = false,
  productType,
  isForSuggestedProduct = false,
  showPricePerBox = false,
  showTotalPrice = false,
  showSalePrice = true,
}) => {
  const { t } = useTranslation()

  if (!price) return null

  const initialPrice = price.listPrice ? +price.listPrice : null
  const currentPrice = price.offerPrice ? +price.offerPrice : null
  const hasInitialPriceDiscount = 'showListPrice' in price ? price.showListPrice : false

  const quantity = (productQuantity && parseInt(productQuantity[0])) ?? totalBoxes

  return (
    <ProductPriceWrapper className={`${initialPrice !== undefined ? 'product-price' : 'product-price no-discount'}`}>
      {isPDP ? (
        <>
          {(isCL || isCLAccessories) && showPricePerBox && (
            <>
              <ProductPricePricePerBoxContainer>
                <ProductPricePricePerBox variant="body1">{t('ContactLenses.Labels.PerBox')}</ProductPricePricePerBox>
                <ProductPricePricePerBoxPrices>
                  {hasInitialPriceDiscount && (
                    <ProductPricePricePerBoxInitialPrice variant="body2">
                      <Price price={initialPrice} currency={price.currency} />
                    </ProductPricePricePerBoxInitialPrice>
                  )}
                  {currentPrice && (
                    <ProductPricePricePerBoxCurrentPrice variant="body1">
                      <Price price={currentPrice} currency={price.currency} />
                    </ProductPricePricePerBoxCurrentPrice>
                  )}
                </ProductPricePricePerBoxPrices>
              </ProductPricePricePerBoxContainer>
            </>
          )}
          {showTotalPrice && (
            <ProductPriceContainerPDP isForSuggestedProduct={isForSuggestedProduct}>
              <ProductPriceLabel>{!isForSuggestedProduct && t(getPriceLabel(productType))}</ProductPriceLabel>
              <div>
                <ProductPriceDetails>
                  {hasInitialPriceDiscount && (
                    <ProductPriceInitialPricePDP>
                      <Price price={getFinalPrice(initialPrice)} currency={price.currency} />
                    </ProductPriceInitialPricePDP>
                  )}
                  {currentPrice && (
                    <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact}>
                      <Price price={getFinalPrice(currentPrice)} currency={price.currency} />
                    </ProductPriceCurrentPrice>
                  )}
                </ProductPriceDetails>
              </div>
            </ProductPriceContainerPDP>
          )}
          {showSalePrice && price && (
            <ProductTilePrice
              offerPrice={<Price price={currentPrice} currency={price.currency} />}
              rrp={hasInitialPriceDiscount ? <Price price={initialPrice} currency={price.currency} /> : <></>}
            />
          )}
          {!isForSuggestedProduct && (
            <KlarnaMessageContainerPDP>
              <KlarnaOnSiteMessage
                variant={'credit-promotion-auto-size'}
                grandTotal={
                  !!currentPrice ? getFinalPrice(currentPrice, totalBoxes, isCL, isCLAccessories, quantity) || '' : ''
                }
              />
            </KlarnaMessageContainerPDP>
          )}
        </>
      ) : (
        <ProductPriceContainer isPDP={isPDP} isCompact={isCompact} isForSuggestedProduct={isForSuggestedProduct}>
          <ProductPriceDetails>
            {hasInitialPriceDiscount && (
              <>
                <ProductPriceInitialPrice>
                  <Price price={initialPrice} currency={price.currency} />
                </ProductPriceInitialPrice>
              </>
            )}
            {currentPrice && (
              <ProductCurrentPriceContainer isPDP={isPDP}>
                <ProductPriceCurrentPrice isPDP={isPDP} isCompact={isCompact || false}>
                  <Price price={currentPrice} currency={price.currency} />
                </ProductPriceCurrentPrice>
              </ProductCurrentPriceContainer>
            )}
          </ProductPriceDetails>
        </ProductPriceContainer>
      )}
    </ProductPriceWrapper>
  )
}

export default ProductPriceNew
