import { ILXTeaser } from '@typesApp/cmsPlacement/LXTeaser'

export const isShoppableTeaser = (teaser: ILXTeaser | undefined): boolean => {
  if (!teaser) return false
  const hotZones = teaser.hotZones
  return Array.isArray(hotZones) && hotZones.length > 0
}

export const hasOverlay = (item: ILXTeaser | undefined): boolean => {
  return Boolean(item?.teaserOverlay2Style?.includes('shadow'))
}

export const getToLink = (item: ILXTeaser | undefined) => {
  const { teaserLXCallToActionSettings } = item || {}
  return `${teaserLXCallToActionSettings?.[0]?.target?.formattedUrl ?? ''}`
}

export const hasTermsAndConditions = (item: ILXTeaser | undefined): boolean =>
  Boolean(item?.targetsTermsAndConditions?.target)
